import React, { Component } from 'react';
import { HellToggle, HellInput, HellSelect } from './inputs';
// import HellInput from './hellInput';
import ButtonWithPopup from './buttonWithPopup';
import { ConfigContext } from '../context/configurationContext';
import { coinSymbolToHuman } from '../lib/functions';
import HellTextarea from './inputs/hellTextarea';
import { getDataForExport, importDataToStorage } from '../lib/storage';

class AppGeneralSettingsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsData: '',
        };
    }

    onNotificationLimitChange = (value) => {
        let newValue = parseFloat(value) || 0;
        if (newValue <= 1) {
            newValue = 1;
        }
        this.context.changeConfig('notificationLimit', value);
    }

    handleExportSettings = () => {
        this.setState({settingsData: getDataForExport()});
    }

    handleImportSettings = () => {
        const importResult = importDataToStorage(this.state.settingsData);
        if (importResult) {
            // Reload App
            window.location.reload(false);
        }
    }

    render() {
        const sidebarGainersOrderByOptions = [
            ['30s', 'Price change in 30 seconds'],
            ['1m', 'Price change in 1 minute'],
            ['2m', 'Price change in 2 minutes'],
            ['3m', 'Price change in 3 minutes'],
            ['5m', 'Price change in 5 minutes'],
            ['15m', 'Price change in 15 minutes'],
            ['30m', 'Price change in 30 minutes'],
            ['60m', 'Price change in 1 hour'],
            ['24h', 'Price change in 1 day'],
            ['dailyVolume', 'Volume shift'],
            ['dailyMX', 'Daily Min/Max range'],
            ['hourlyMX', 'Hourly Min/Max range']
        ];

        const favoriteCoinOptions = [];
        let coinData = this.props.coins;
        let coinKeys = Object.keys(coinData);
        coinKeys.sort();
        coinKeys.forEach((coinSymbol) => {
            favoriteCoinOptions.push([coinSymbol, coinSymbolToHuman(coinSymbol)]);
        });


        return <ButtonWithPopup buttonLabel="Config" className="popup--settings">
            <div className="settings--row">
                <div className="settings--col">
                    <h3 className="headline-with-line">Toolbar</h3>
                    <div className="display-toggles-per-line">
                        <HellToggle name="toolbar-show-gainer" label="Show Best Worst in top Toolbar" onChange={() => { this.context.toggleConfig('toolbarShowGainers') }} isActive={this.context.toolbarShowGainers} />
                        <div>
                            <HellInput name="toolbar-gainers-count" type="number" label="Number of Coins to show from each end" value={this.context.toolbarGainersLimit} onChange={(value) => { this.context.changeConfig('toolbarGainersLimit', value) }} />
                        </div>
                        <div>
                            <HellSelect name="toolbar-gainers-orderby" label="Order By" value={this.context.toolbarGainersOrderBy} onChange={(value) => { this.context.changeConfig('toolbarGainersOrderBy', value) }} options={sidebarGainersOrderByOptions} />
                        </div>
                    </div>
                </div>
                <div className="settings--col">
                    <h3 className="headline-with-line">General</h3>
                    <div className="display-toggles-per-line">
                        <div>
                            <HellSelect name="favorite-coin" label="Favorite Coin" value={this.context.favoriteCoin} onChange={(value) => { this.context.changeConfig('favoriteCoin', value) }} options={favoriteCoinOptions} />
                            <HellToggle name="reverse-price-change-charts" label="Reverse Price Change Charts" onChange={() => { this.context.toggleConfig('reversePriceChangeCharts') }} isActive={this.context.reversePriceChangeCharts} />
                            <HellToggle name="price-change-charts-include-coin-price" label="Price Change Charts include current Coin Price" onChange={() => { this.context.toggleConfig('priceChangeChartsIncludeCoinPrice') }} isActive={this.context.priceChangeChartsIncludeCoinPrice} />
                        </div>
                    </div>
                </div>
                <div className="settings--col">
                    <h3 className="headline-with-line">Notifications</h3>
                    <div className="display-toggles-per-line">
                        <HellToggle name="notifications-general-on" label="Enable notifications" onChange={() => { this.context.toggleNotifications() }} isActive={this.context.notificationsEnabled} isDisabled={Notification.permission === 'denied'} />
                        <HellInput name="notifications-limit" type="number" label="Price % change limit for notification (min 1)" value={this.context.notificationLimit} onChange={this.onNotificationLimitChange} />
                        <HellToggle name="notifications-include-negative" label="Include negative price change" onChange={() => { this.context.toggleConfig('notificationIncludeNegative') }} isActive={this.context.notificationIncludeNegative} />
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line"></div>
                </div>
            </div>
            <h3 className="headline-with-line">Coin Cell</h3>
            <div className="settings--row">
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="coin-cell-show-arrow" label="Show Trend Arrow" onChange={() => { this.context.toggleConfig('coinCellTrendArrowVisible') }} isActive={this.context.coinCellTrendArrowVisible} />
                        <HellToggle name="coin-cell-show-diff-30s" label="Show 30s price difference " onChange={() => { this.context.toggleConfig('coinCellShowDiff30s') }} isActive={this.context.coinCellShowDiff30s} />
                        <HellToggle name="coin-cell-show-diff-1m" label="Show 1m price difference " onChange={() => { this.context.toggleConfig('coinCellShowDiff1m') }} isActive={this.context.coinCellShowDiff1m} />
                        <HellToggle name="coin-cell-show-diff-2m" label="Show 2m price difference " onChange={() => { this.context.toggleConfig('coinCellShowDiff2m') }} isActive={this.context.coinCellShowDiff2m} />
                        <HellToggle name="coin-cell-show-diff-3m" label="Show 3m price difference " onChange={() => { this.context.toggleConfig('coinCellShowDiff3m') }} isActive={this.context.coinCellShowDiff3m} />
                        <HellToggle name="coin-cell-show-diff-5m" label="Show 5m price difference " onChange={() => { this.context.toggleConfig('coinCellShowDiff5m') }} isActive={this.context.coinCellShowDiff5m} />
                        <HellToggle name="coin-cell-show-diff-15m" label="Show 15m price difference " onChange={() => { this.context.toggleConfig('coinCellShowDiff15m') }} isActive={this.context.coinCellShowDiff15m} />
                        <HellToggle name="coin-cell-show-diff-30m" label="Show 30m price difference " onChange={() => { this.context.toggleConfig('coinCellShowDiff30m') }} isActive={this.context.coinCellShowDiff30m} />
                        <HellToggle name="coin-cell-show-diff-60m" label="Show 60m price difference " onChange={() => { this.context.toggleConfig('coinCellShowDiff60m') }} isActive={this.context.coinCellShowDiff60m} />
                    </div>
                    <div className="display-toggles-per-line">
                        <div>
                            <HellInput name="sidebar-coinCellColumns" type="number" label="Maximum column count" value={this.context.coinCellColumns} onChange={(value) => { this.context.changeConfig('coinCellColumns', value) }} />
                        </div>
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="coin-cell-show-daily-minmax" label="Show Daily Min/Max" onChange={() => { this.context.toggleConfig('coinCellShowDailyMinMax') }} isActive={this.context.coinCellShowDailyMinMax} />
                        <HellToggle name="coin-cell-show-daily-minmax-i" label="Show Daily Min/Max Indicator" onChange={() => { this.context.toggleConfig('coinCellShowDailyMinMaxIndicator') }} isActive={this.context.coinCellShowDailyMinMaxIndicator} />
                        <HellToggle name="coin-cell-show-hourly-minmax" label="Show Hourly Min/Max" onChange={() => { this.context.toggleConfig('coinCellShowHourlyMinMax') }} isActive={this.context.coinCellShowHourlyMinMax} />
                        <HellToggle name="coin-cell-show-hourly-minmax-i" label="Show Hourly Min/Max Indicator" onChange={() => { this.context.toggleConfig('coinCellShowHourlyMinMaxIndicator') }} isActive={this.context.coinCellShowHourlyMinMaxIndicator} />
                        <HellToggle name="coin-cell-show-daily-minmax-perc" label="Show Daily Min/Max Percentage" onChange={() => { this.context.toggleConfig('coinCellShowDailyMinMaxPerc') }} isActive={this.context.coinCellShowDailyMinMaxPerc} />
                        <HellToggle name="coin-cell-add-h-minmax-to-d-i" label="Add Hourly Min/Max to Daily Indicator" onChange={() => { this.context.toggleConfig('coinCellAddHourlyMinMaxToDailyIndicator') }} isActive={this.context.coinCellAddHourlyMinMaxToDailyIndicator} />
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="coin-cell-show-daily-coin-vol" label="Show Daily Coin Volume" onChange={() => { this.context.toggleConfig('coinCellShowDailyVolumeCoins') }} isActive={this.context.coinCellShowDailyVolumeCoins} />
                        <HellToggle name="coin-cell-show-daily-usdt-vol" label="Show Daily USDT Volume" onChange={() => { this.context.toggleConfig('coinCellShowDailyVolumeUsdt') }} isActive={this.context.coinCellShowDailyVolumeUsdt} />
                        <HellToggle name="coin-cell-show-daily-price-change" label="Show Daily Price Change" onChange={() => { this.context.toggleConfig('coinCellShowDailyVolumeChange') }} isActive={this.context.coinCellShowDailyVolumeChange} />
                        <HellToggle name="coin-cell-show-daily-p-price-change" label="Show Daily Percentage Price Change" onChange={() => { this.context.toggleConfig('coinCellShowDailyVolumeChangePerc') }} isActive={this.context.coinCellShowDailyVolumeChangePerc} />
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line"></div>
                </div>
            </div>
            <h3 className="headline-with-line">Sidebar</h3>
            <div className="settings--row">
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <div>
                            <HellInput name="sidebar-gainers-coin-count" type="number" label="Number of Coins to show from each end" value={this.context.sidebarGainersNumber} onChange={(value) => { this.context.changeConfig('sidebarGainersNumber', value) }} />
                        </div>
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="sidebar-show-portfolio" label="Show Portfolio" onChange={() => { this.context.toggleConfig('sidebarShowPortfolio') }} isActive={this.context.sidebarShowPortfolio} />
                    </div>
                </div>
            </div>
            <div className="settings--row">
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="sidebar-show-gainers-a" label="Show 1st Section" onChange={() => { this.context.toggleConfig('sidebarGainersShowSectionA') }} isActive={this.context.sidebarGainersShowSectionA} />
                        <div>
                            <HellSelect name="sidebar-gainers-orderby" label="1st Section Order By" value={this.context.sidebarGainrserOrderBy} onChange={(value) => { this.context.changeConfig('sidebarGainrserOrderBy', value) }} options={sidebarGainersOrderByOptions} />
                        </div>
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="sidebar-show-gainers-b" label="Show 2nd Section" onChange={() => { this.context.toggleConfig('sidebarGainersShowSectionB') }} isActive={this.context.sidebarGainersShowSectionB} />
                        <div>
                            <HellSelect name="sidebar-2nd-gainers-orderby" label="2nd Section Order By" value={this.context.sidebarScndGainersOrderBy} onChange={(value) => { this.context.changeConfig('sidebarScndGainersOrderBy', value) }} options={sidebarGainersOrderByOptions} />
                        </div>
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="sidebar-show-gainers-c" label="Show 3rd Section" onChange={() => { this.context.toggleConfig('sidebarGainersShowSectionC') }} isActive={this.context.sidebarGainersShowSectionC} />
                        <div>
                            <HellSelect name="sidebar-3rd-gainers-orderby" label="3rd Section Order By" value={this.context.sidebarThirdGainersOrderBy} onChange={(value) => { this.context.changeConfig('sidebarThirdGainersOrderBy', value) }} options={sidebarGainersOrderByOptions} />
                        </div>
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="sidebar-show-gainers-D" label="Show 4th Section" onChange={() => { this.context.toggleConfig('sidebarGainersShowSectionD') }} isActive={this.context.sidebarGainersShowSectionD} />
                        <div>
                            <HellSelect name="sidebar-4th-gainers-orderby" label="4th Section Order By" value={this.context.sidebarForthGainersOrderBy} onChange={(value) => { this.context.changeConfig('sidebarForthGainersOrderBy', value) }} options={sidebarGainersOrderByOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="headline-with-line">Notices</h3>
            <div className="settings--row">
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="notices-show-sidebar" label="Show sidebar" onChange={() => { this.context.toggleConfig('noticesShowSidebar') }} isActive={this.context.noticesShowSidebar} />
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <div>
                            <HellInput name="notices-visible-count" type="number" label="Visible notices" value={this.context.noticesVisibleCount} onChange={(value) => { this.context.changeConfig('noticesVisibleCount', value) }} />
                        </div>
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="notices-show-coin-cell" label="Show in coin cell" onChange={() => { this.context.toggleConfig('noticesShowCoinCell') }} isActive={this.context.noticesShowCoinCell} />
                    </div>
                </div>
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <HellToggle name="notices-only-usdt" label="Show only USDT" onChange={() => { this.context.toggleConfig('noticesShowOnlyUsdt') }} isActive={this.context.noticesShowOnlyUsdt} />
                    </div>
                </div>
            </div>
            <h3 className="headline-with-line">Export / Import settings</h3>
            <div className="settings--row">
                <div className="settings--col">
                    <div className="display-toggles-per-line">
                        <div>
                            <HellTextarea name="settings-dump" label="Data" value={this.state.settingsData} onChange={(value) => { this.setState({settingsData: value}) }} />
                            <br/>
                            <button className="button" onClick={this.handleExportSettings}>Export</button>
                            <button className="button" onClick={this.handleImportSettings}>Import</button>
                        </div>
                    </div>
                </div>
            </div>
        </ButtonWithPopup>
    }
}

AppGeneralSettingsPopup.contextType = ConfigContext;

export default AppGeneralSettingsPopup;
