import React, { Component } from 'react';
import settings from '../config';
import { formatPercents } from '../lib/functions';

class CoinDiffRow extends Component {
    render() {
        let trend = this.props.trend;
        let label = this.props.label
        return <div className="coin-cell--diff-row" data-trend={trend >= 0 ? 'p' : 'n'}>
            <div className="coin-cell--diff-row--label">{label}</div>
            <div className="coin-cell--diff-row--value">{formatPercents(trend, settings.decimalLength)}</div>
        </div>
    }
}

export default CoinDiffRow;