import React, { Component } from 'react';

class HellSelect extends Component {
    handleChange = (event) => {
        if (typeof this.props.onChange === 'function') {
            let value = event.target.value;
            this.props.onChange.call(this, value || '');
        }
    }

    render() {
        let options = [];
        if (Array.isArray(this.props.options)) {
            this.props.options.forEach(el => {
                options.push(<option key={'opt' + el[0]} value={el[0]}>{el[1]}</option>);
            });
        }
        return <>
            <label className="hell--select-label" htmlFor={this.props.name}>{this.props.label}</label>
            <br />
            <select className="hell--select" value={this.props.value} onChange={this.handleChange} name={this.props.name}>
                <option>--- Select Option ---</option>
                {options}
            </select>
        </>
    }
}

export default HellSelect;