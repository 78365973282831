import React from 'react';

export const ConfigContext = React.createContext({
    toolbarShowGainers: false,
    filterShowPrice: false,
    filterOrderByPrice: false,
    sidebarGainersCount: 10,
    coinCellColumns: 10,
    coinCellTrendArrowVisible: true,
    coinCellShowDiff30s: false,
    coinCellShowDiff1m: true,
    coinCellShowDiff2m: false,
    coinCellShowDiff3m: true,
    coinCellShowDiff5m: true,
    coinCellShowDiff15m: true,
    coinCellShowDiff30m: true,
    coinCellShowDiff60m: false,
    coinCellShowDailyMinMaxPerc: false,
    coinCellShowDailyMinMax: true,
    coinCellShowDailyMinMaxIndicator: true,
    coinCellShowHourlyMinMax: true,
    coinCellShowHourlyMinMaxIndicator: true,
    coinCellAddHourlyMinMaxToDailyIndicator: false,
    coinCellShowDailyVolumeCoins: false,
    coinCellShowDailyVolumeUsdt: true,
    coinCellShowDailyVolumeChange: false,
    coinCellShowDailyVolumeChangePerc: true,
    sidebarGainersNumber: 10,
    sidebarGainersShowSectionA: true,
    sidebarGainrserOrderBy: '60m',
    sidebarGainersShowSectionB: false,
    sidebarScndGainersOrderBy: '60m',
    sidebarGainersShowSectionC: false,
    sidebarThirdGainersOrderBy: '60m',
    sidebarGainersShowSectionD: false,
    sidebarForthGainersOrderBy: '60m',
    toolbarGainersLimit: 2,
    toolbarGainersOrderBy: '60m',
    favoriteCoin: 'BTCUSDT',
    notificationsEnabled: false,
    notificationLimit: 2,
    notificationIncludeNegative: false,
    noticesShowSidebar: true,
    noticesVisibleCount: 25,
    noticesShowCoinCell: true,
    noticesShowOnlyUsdt: false,
    reversePriceChangeCharts: false,
    priceChangeChartsIncludeCoinPrice: false,
    sidebarShowPortfolio: true,
    changeConfig: () => { },
    toggleConfig: () => { },
    toggleNotifications: () => { }
});