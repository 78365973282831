import React, { Component } from 'react';

class HellToggle extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         active: false
    //     };
    // }

    onClick = () => {
        if (this.props.isDisabled) {
            return;
        }

        if (this.props.onChange && typeof this.props.onChange == 'function') {
            this.props.onChange.call();
        }
    }

    render() {
        return <div className={'hell--toggle' + (this.props.isActive ? ' active' : '') + (this.props.isDisabled ? ' disabled' : '')} onClick={this.onClick}>
            <div className="hell--toggle--indicator"></div>
            <div className="hell--toggle--label">{this.props.label || ''}</div>
        </div>
    }
}

export default HellToggle;