import React, { Component } from 'react';

class Icon extends Component {
    render() {
        let fileName = '';
        switch (this.props.type) {
            case 'trash':
                fileName = 'icon-trash';
                break;
            case 'filter':
                fileName = 'icon-filter';
                break;
            case 'cog':
                fileName = 'icon-cog';
                break;
            default:
                fileName = '';
                break;
        }

        if (!fileName) {
            return null;
        }

        if (this.props.theme === 'light') {
            fileName = fileName + '-light';
        }

        return <img src={'/img/' + fileName + '.svg'} alt={fileName} width="32" height="32" />
    }
}

export default Icon;
