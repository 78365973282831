import React, { Component } from 'react';
import { coinSymbolToHuman, formatPrice } from '../lib/functions';

class SidebarPortfolioRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            formInput: this.props.coinAmmount ?? '',
            formPriceInput: this.props.coinInitialPrice ?? '',
        };
    }

    handleRemoveButton = (coinKey) => {
        if (!this.props.onPortfolioRemove) {
            return;
        }
        this.props.onPortfolioRemove.call(null, coinKey);
    };

    handleSymbolClick = () => {
        this.setState({showForm: true});
    }

    handleFormChange = (event) => {
        let value = event.target.value;
        if (value !== '' && this.props.type === 'number') {
            value = parseFloat(value);
        }
        this.setState({formInput: value});
    }

    handleFormPriceChange = (event) => {
        let value = event.target.value;
        if (value !== '' && this.props.type === 'number') {
            value = parseFloat(value);
        }
        this.setState({formPriceInput: value});
    }

    handleFormSave = (coinKey) => {
        if (!this.props.onAmmountUpdate) {
            return;
        }
        this.props.onAmmountUpdate.call(null, coinKey, this.state.formInput, this.state.formPriceInput);
        this.setState({showForm: false});
    }

    handleFormClose = () => {
        this.setState({
            formInput: this.props.coinAmmount || 0,
            showForm: false,
        });
    }

    render() {
        const coinKey = this.props.coinKey || '';
        const portfolioKey = this.props.portfolioKey || '';
        const coinAmmount = this.props.coinAmmount || 0;
        const coinPrice = this.props.coinPrice || 0;
        const coinInitialPrice = this.props.coinInitialPrice || 0;
        let difference = 0;
        if (coinInitialPrice !== 0 && coinPrice !== 0) {
            difference = coinPrice / coinInitialPrice;
        }

        return <div className='sidebar-portfolio--content--row text-size--md' key={"pfolio-" + portfolioKey}>
            {this.state.showForm ? (
                <div className='sidebar-portfolio--content--row--form'>
                    <input className="hell--input" type="number" placeholder='Amount' value={this.state.formInput} name={'portfolio-input-' + portfolioKey} onChange={this.handleFormChange} />
                    <input className="hell--input" type="number" placeholder='Price' value={this.state.formPriceInput} name={'portfolio-input-price-' + portfolioKey} onChange={this.handleFormPriceChange} />
                    <button className='button' onClick={() => { this.handleFormSave(portfolioKey) }}>Save</button>
                    <button className='button' onClick={this.handleFormClose}>Cancel</button>
                </div>
            ) : (
                <>
                    <div onClick={this.handleSymbolClick}><span className='color-orange'>{coinSymbolToHuman(coinKey, true)}</span><br/>{` (${coinAmmount})`}</div>
                    <div className='sidebar-portfolio--content--row--price-wrap text-align--right'>
                        {difference !== 0 ? (
                            <>
                                <span className={difference - 1 < 0 ? 'color-red' : 'color-green'}>{Math.round((difference - 1) * 10000) / 100}% </span><br/>
                            </>
                        ): null}
                        <span className='sidebar-portfolio--content--row--price'>${formatPrice(Math.round(coinAmmount * coinPrice * 100) / 100)}</span>
                        <button className='button sidebar-portfolio--content--row--removeBtn' onClick={() => { this.handleRemoveButton(portfolioKey) }}>Remove</button>
                    </div>
                </>
            )}
        </div>
    }
}

// SidebarPortfolioRow.contextType = ConfigContext;

export default SidebarPortfolioRow;