import React, { Component } from 'react';
import { ConfigContext } from '../context/configurationContext';
import { getFromLocalstorage, saveToLocalStorage } from '../lib/storage';
import SidebarPortfolioRow from './SidebarPortfolioRow';
import { coinSymbolToHuman, formatPrice } from '../lib/functions';

class SidebarPortfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            newPortfplioCoinKey: '',
            newPortfolioCoinAmmount: '',
            newPortfolioCoinPrice: '',
            coinAmmounts: getFromLocalstorage('coinAmmounts') || {},
        };
    }

    handlePortfolioUpdate = (portfolioKey, coinAmmount, coinPrice) => {
        const coinAmmounts = {
            ...(getFromLocalstorage('coinAmmounts') || {}),
            [portfolioKey]: `${coinAmmount}|${coinPrice}`,
        };
        saveToLocalStorage('coinAmmounts', coinAmmounts);
        this.setState({
            coinAmmounts: coinAmmounts,
        });
    }

    handlePortfolioRemove = (portfolioKey) => {
        const coinAmmounts = {
            ...(getFromLocalstorage('coinAmmounts') || {}),
        };
        if (portfolioKey in coinAmmounts) {
            delete coinAmmounts[portfolioKey];
        }
        saveToLocalStorage('coinAmmounts', coinAmmounts);
        this.setState({
            coinAmmounts: coinAmmounts,
        });
    }

    handleAddNewPortfolioButton = () => {
        this.setState({
            showForm: true,
        });
    }

    handleNewPortfolioInputChange = (event) => {
        let value = event.target.value;
        if (value !== '' && this.props.type === 'number') {
            value = parseFloat(value);
        }
        this.setState({newPortfolioCoinAmmount: value});
    }

    handleNewPortfolioInputPriceChange = (event) => {
        let value = event.target.value;
        if (value !== '' && this.props.type === 'number') {
            value = parseFloat(value);
        }
        this.setState({newPortfolioCoinPrice: value});
    }

    handleAddNewPortfolioSave = () => {
        if (!this.state.newPortfplioCoinKey || this.state.newPortfplioCoinKey === '') {
            return;
        }
        const unixSeconds = Math.floor(Date.now() / 1000);
        const portfolioCoinKey = `${this.state.newPortfplioCoinKey}|${unixSeconds}`;
        this.handlePortfolioUpdate(
            portfolioCoinKey,
            this.state.newPortfolioCoinAmmount,
            this.state.newPortfolioCoinPrice,
        );
        this.setState({
            showForm: false,
            newPortfplioCoinKey: '',
            newPortfolioCoinAmmount: '',
            newPortfolioCoinPrice: '',
        });
    }

    handleAddNewPortfilioClose = () => {
        this.setState({
            showForm: false,
            newPortfplioCoinKey: '',
            newPortfolioCoinAmmount: 0,
        });
    }

    handleNewPortfolioInputCoinChange = (event) => {
        let value = event.target.value;
        this.setState({
            newPortfplioCoinKey: value || '',
        });
    }

    render() {
        if (!this.context.sidebarShowPortfolio) {
            return <></>;
        }

        let portfolioTotal = 0;

        return <div className="sidebar-portfolio">
            <div className='sidebar-portfolio--head'>
                <h3 className='sidebar-portfolio--title'>Portfolio</h3>
                {!this.state.showForm && (<button className="button" onClick={this.handleAddNewPortfolioButton}>Add new</button>)}
            </div>
            <div className='sidebar-portfolio--content'>
                {this.state.showForm && (<div className="sidebar-portfolio--content--form">
                    <select className="hell--select" value={this.state.newPortfplioCoinKey} onChange={this.handleNewPortfolioInputCoinChange}>
                        <option>--- Select Coin ---</option>
                        {Object.keys(this.props.coins).sort().map((coin) => {
                            return <option
                                key={'pfolio-optn-'+coin}
                                value={coin}
                                >{coinSymbolToHuman(coin)}</option>
                        })}
                    </select>
                    <input className="hell--input" type="number" placeholder='Amount' value={this.state.newPortfolioCoinAmmount} onChange={this.handleNewPortfolioInputChange} />
                    <input className="hell--input" type="number" placeholder='Price' value={this.state.newPortfolioCoinPrice} onChange={this.handleNewPortfolioInputPriceChange} />
                    <button className="button" onClick={this.handleAddNewPortfolioSave}>Add</button>
                    <button className="button" onClick={this.handleAddNewPortfilioClose}>Cancel</button>
                </div>)}
                {Object.keys(this.state.coinAmmounts).sort().map((portfolioKey) => {
                    let coinAmmount = 0;
                    let coinInitialPrice = 0;
                    if (this.state.coinAmmounts[portfolioKey] && this.state.coinAmmounts[portfolioKey] !== '') {
                        if (String(this.state.coinAmmounts[portfolioKey]).indexOf('|') < 0) {
                            // Old Portfolio data
                            coinAmmount = parseFloat(this.state.coinAmmounts[portfolioKey] || 0);
                        } else {
                            // New Portfolio data
                            const portfolioCoinData = this.state.coinAmmounts[portfolioKey].split('|');
                            coinAmmount = parseFloat(portfolioCoinData[0] ?? 0);
                            coinInitialPrice = parseFloat(portfolioCoinData[1] ?? 0);
                        }
                    }
                    let coinKey = '';
                    if (String(portfolioKey).indexOf('|') < 0 ) {
                        // Old key
                        coinKey = portfolioKey;
                    } else {
                        // New Key
                        coinKey = portfolioKey.split('|')[0];
                    }
                    const coinPrice = parseFloat(this.props.coins[coinKey]?.a || 0) ;
                    const coinTotal = Math.round(coinAmmount * coinPrice * 100) / 100;
                    portfolioTotal += coinTotal;
                    return <SidebarPortfolioRow
                        key={'pfolio-' + portfolioKey}
                        coinKey={coinKey}
                        portfolioKey={portfolioKey}
                        coinPrice={coinPrice}
                        coinAmmount={coinAmmount}
                        coinInitialPrice={coinInitialPrice}
                        onAmmountUpdate={this.handlePortfolioUpdate}
                        onPortfolioRemove={this.handlePortfolioRemove}
                    />
                })}
                <div className='sidebar-portfolio--content--row' key={"pfolio-total"}>
                    <div>Total</div>
                    <div>${formatPrice(Math.round(portfolioTotal * 100) / 100)}</div>
                </div>
            </div>
        </div>
    }
}

SidebarPortfolio.contextType = ConfigContext;

export default SidebarPortfolio;