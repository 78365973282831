import React, { Component } from 'react';
import Icon from '../icons/icon';

class IconButton extends Component {
    render() {
        let theme = (this.props.theme === 'light') ? 'light' : 'dark';
        let iconTheme = theme === 'light' ? 'dark' : 'light';
        return <button className={'button button-icon' + (this.props.className ? ' ' + this.props.className : '')} onClick={this.props.onClick} data-theme={theme}>
            <Icon type={this.props.icon} theme={iconTheme} />
        </button>
    }
}

export default IconButton;
