const getFromLocalstorage = (key) => {
    let ls = {};
    if (localStorage) {
        try {
            ls = JSON.parse(localStorage.getItem('hell-settings')) || {};
        } catch (e) {
            // Ignore
        }
    }
    return ls[key];
}

const saveToLocalStorage = (key, value) => {
    if (localStorage) {
        var ls = JSON.parse(localStorage.getItem('hell-settings')) || {};
        ls[key] = value;
        localStorage.setItem('hell-settings', JSON.stringify(ls));
    }
}

const getDataForExport = () => {
    return localStorage.getItem('hell-settings') ?? '';
}

const importDataToStorage = (jsonData) => {
    try {
        JSON.parse(jsonData);    
        localStorage.setItem('hell-settings', jsonData);
        return true;
    } catch (error) {
        alert('Invalid JSON');
        return false;
    }
}

export { getDataForExport, getFromLocalstorage, importDataToStorage, saveToLocalStorage };
