const coinSymbolToHuman = (symbol, onlyCoin = false) => {
    if (onlyCoin) {
        return String(symbol).replace('USDT', '');
    }
    return String(symbol).replace('USDT', ' / USDT');
}

// const formatPrice = (price, fixDecimals = false, decimals = 2) => {
//     let number = fixDecimals ? price.toFixed(decimals) : price;
//     return number.toString().replace(/\B(?=(?=\d*\.)(\d{3})+(?!\d))/g, ' ');
// }

const formatPrice = (price, fixDecimals = false, decimals = 2) => {
    let number = fixDecimals ? parseFloat(price).toFixed(decimals) : parseFloat(price);
    let fNumber = number.toString().replace(/\B(?=(?=\d*\.)(\d{3})+(?!\d))/g, ' ');
    if (fixDecimals) {
        return fNumber;
    }
    let indexOfDot = fNumber.indexOf('.');
    if (indexOfDot > 0) {
        let fractions = fNumber.length - indexOfDot - 1;
        if (fractions === 0) {
            return fNumber + '00';
        }
        if (fractions === 1) {
            return fNumber + '0';
        }
        return fNumber;
    }
    return fNumber + '.00';
}

const formatPercents = (percents, decimals = 2) => {
    return parseFloat(percents).toFixed(decimals).toString() + '%';
}

const calculatePercentageDiff = (beginPrice, endPrice) => {
    if (beginPrice === 0 || endPrice === 0) {
        return 0;
    }
    return Math.round(((endPrice / beginPrice) - 1) * 10000) / 100;
}

const showNotification = (title, message, tag) => {
    if (Notification.permission === 'granted') {
        /*let notification = */new Notification(title, {
        body: message,
        // icon: process.env.PUBLIC_URL + '/android-chrome-192x192.png',
        badge: process.env.PUBLIC_URL + '/favicon-32x32.png',
        // image: process.env.PUBLIC_URL + '/favicon-32x32.png',
        tag: tag
    });
        // notification.onclose = (ev) => {
        //     console.log('NOTIF CLOSED', this, ev)
        //     // ev.target = Notification.tag
        // };
    }
}

export { coinSymbolToHuman, formatPrice, formatPercents, calculatePercentageDiff, showNotification };
