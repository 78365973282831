import React, { Component } from 'react';
import { ConfigContext } from '../context/configurationContext';
import { formatPercents } from '../lib/functions';
import settings from '../config';
import NoticeIcon from './NoticeIcon';

class SidebarNoticeRow extends Component {
    render() {
        // const noticeType = this.props.notice.n || '';
        // const coinSymbol = this.props.notice.s || '';
        const eventType = this.props.notice.e || '';
        const priceChange = this.props.notice.c || '';
        const noticePeriod = this.props.notice.p || '';
        const noticeTimestamp = this.props.notice.t || null;
        const noticeBaseAsset = this.props.notice.b || '';
        const noticeQuotaAsset = this.props.notice.a || '';
        const includeDate = !!this.props.includeDate;
        let noticeTime = '';

        if (this.context.noticesShowOnlyUsdt && noticeQuotaAsset !== 'USDT') {
            return <></>
        }

        let icon = 'whale';
        let iconColor = 'white';
        let label = '';

        switch (eventType) {
            case 'UP_BREAKTHROUGH': {
                iconColor = 'green';
                if (noticePeriod === 'DAY_1') {
                    icon = 'line-up';
                    label = 'New 24hr High';
                } else if (noticePeriod === 'WEEK_1') {
                    icon = 'line-up2';
                    label = 'New 7day High';
                } else if (noticePeriod === 'MONTH_1') {
                    icon = 'line-up3';
                    label = 'New 30day High';
                } else {
                    icon = 'line-up3';
                    label = 'New ? High';
                }
                break;
            }
            case 'DOWN_BREAKTHROUGH': {
                iconColor = 'red';
                if (noticePeriod === 'DAY_1') {
                    icon = 'line-down';
                    label = 'New 24hr Low';
                } else if (noticePeriod === 'WEEK_1') {
                    icon = 'line-down2';
                    label = 'New 7day Low';
                } else if (noticePeriod === 'MONTH_1') {
                    icon = 'line-down3';
                    label = 'New 30day Low';
                } else {
                    icon = 'line-down3';
                    label = 'New ? Low';
                }
                break;
            }
            case 'UP_1': {
                icon = 'up';
                iconColor = 'green';
                if (noticePeriod === 'MINUTE_5') {
                    label = 'Small 5min Rise';
                } else if (noticePeriod === 'HOUR_2') {
                    label = 'Small 2hr Rise';
                } else {
                    label = 'Small Rise';
                }
                break;
            }
            case 'UP_2': {
                icon = 'up2';
                iconColor = 'green';
                if (noticePeriod === 'MINUTE_5') {
                    label = 'Mid 5min Rise';
                } else if (noticePeriod === 'HOUR_2') {
                    label = 'Mid 2hr Rise';
                } else {
                    label = 'Mid Rise';
                }
                break;
            }
            case 'UP_3': {
                icon = 'up3';
                iconColor = 'green';
                if (noticePeriod === 'MINUTE_5') {
                    label = 'High 5min Rise';
                } else if (noticePeriod === 'HOUR_2') {
                    label = 'High 2hr Rise';
                } else {
                    label = 'High Rise';
                }
                break;
            }
            case 'DOWN_1': {
                icon = 'down';
                iconColor = 'red';
                if (noticePeriod === 'MINUTE_5') {
                    label = 'Small 5min Fall'
                } else if (noticePeriod === 'HOUR_2') {
                    label = 'Small 2hr Fall';
                } else {
                    label = 'Small Fall';
                }
                break;
            }
            case 'DOWN_2': {
                icon = 'down2';
                iconColor = 'red';
                if (noticePeriod === 'MINUTE_5') {
                    label = 'Mid 5min Fall';
                } else if (noticePeriod === 'HOUR_2') {
                    label = 'Mid 2hr Fall';
                } else {
                    label = 'Mid Fall';
                }
                break;
            }
            case 'DOWN_3': {
                icon = 'down3';
                iconColor = 'red';
                if (noticePeriod === 'MINUTE_5') {
                    label = 'High 5min Fall';
                } else if (noticePeriod === 'HOUR_2') {
                    label = 'High 2hr Fall';
                } else {
                    label = 'High Fall';
                }
                break;
            }
            case 'DROP_BACK': {
                icon = 'reverse-down';
                iconColor = 'red';
                label = 'Pullback';
                break;
            }
            case 'RISE_AGAIN': {
                icon = 'reverse-up';
                iconColor = 'green';
                label = 'Rally';
                break;
            }
            case 'HIGH_VOLUME_RISE_3': {
                icon = 'candle-up2';
                iconColor = 'green';
                label = 'High Price up with High Vol';
                break;
            }
            case 'HIGH_VOLUME_RISE_2': {
                icon = 'candle-up2';
                iconColor = 'green';
                label = 'Mid Price up with High Vol';
                break;
            }
            case 'HIGH_VOLUME_RISE_1': {
                icon = 'candle-up';
                iconColor = 'green';
                label = 'Small Price up with High Vol';
                break;
            }
            case 'HIGH_VOLUME_DROP_3': {
                icon = 'candle-down3';
                iconColor = 'red';
                label = 'High Price down with High Vol';
                break;
            }
            case 'HIGH_VOLUME_DROP_2': {
                icon = 'candle-down2';
                iconColor = 'red';
                label = 'Mid Price down with High Vol';
                break;
            }
            case 'HIGH_VOLUME_DROP_1': {
                icon = 'candle-down';
                iconColor = 'red';
                label = 'Small Price down with High Vol';
                break;
            }
            case 'BLOCK_TRADES_BUY': {
                icon = 'whale';
                iconColor = 'green';
                label = 'Large Buy';
                break;
            }
            case 'BLOCK_TRADES_SELL': {
                icon = 'whale';
                iconColor = 'red';
                label = 'Large Sell';
                break;
            }
            default: {        
                icon = 'whale';
                iconColor = 'white';
                label = '??';
                break;
            }
        }

        if (noticeTimestamp) {
            const noticeDate = new Date(noticeTimestamp);
            noticeTime = includeDate
                ? `${noticeDate.getDate()}.${noticeDate.getMonth() + 1}. ${noticeDate.getHours()}:${noticeDate.getMinutes().toString().padStart(2, '0')}`
                : `${noticeDate.getHours()}:${noticeDate.getMinutes().toString().padStart(2, '0')}:${noticeDate.getSeconds().toString().padStart(2, '0')}`;
        }

        return <div className='sidebar-notices--content--row' key={noticeTimestamp}>
            <div className='sidebar-notices--content--row--col sidebar-notices--content--row--col-a'>
                <span><a className="color-orange" rel="noreferrer" href={`https://www.binance.com/en/trade/${noticeBaseAsset}_${noticeQuotaAsset}?layout=pro`} target='_blank'>{`${noticeBaseAsset} / ${noticeQuotaAsset}`}</a></span><br/><span className='color-gray'>{noticeTime}</span>
            </div>
            <div className='sidebar-notices--content--row--col sidebar-notices--content--row--col-b'>
                <span className={priceChange < 0 ? 'color-red' : 'color-green'}>{formatPercents(priceChange * 100, settings.decimalLength)}</span><br/><span className='color-white'>{label}</span>
            </div>
            <div className='sidebar-notices--content--row--col sidebar-notices--content--row--col-c'>
                <div className={`notice-icon ${iconColor === 'red' ? 'notice-icon-red' : 'notice-icon-green'}`}>
                    <NoticeIcon icon={icon} color={iconColor} />
                </div>
            </div>
        </div>
    }
}

SidebarNoticeRow.contextType = ConfigContext;

export default SidebarNoticeRow;