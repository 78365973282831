import React, { Component } from 'react';

class HellTextarea extends Component {
    handleChange = (event) => {
        if (typeof this.props.onChange === 'function') {
            let value = event.target.value;
            this.props.onChange.call(this, value);
        }
    }

    render() {
        return <>
            <label className="hell--input-label" htmlFor={this.props.name}>{this.props.label}</label>
            <br />
            <textarea className="hell--textarea" rows={5} cols={200} name={this.props.name} onChange={this.handleChange} value={this.props.value} />
        </>
    }
}

export default HellTextarea;