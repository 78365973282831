import React, { Component } from 'react';
import SidebarNoticeRow from './SidebarNoticeRow';

class SingleCoinNotices extends Component {
    render() {
        let notices = this.props.notices && Array.isArray(this.props.notices) ? [...this.props.notices] : [];
        notices.reverse();
        return <div className="single-coin-notices">
            <SidebarNoticeRow notice={notices[0]} includeDate />
        </div>
    }
}

export default SingleCoinNotices;