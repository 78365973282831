import React, { Component } from 'react';

class HellInput extends Component {
    handleChange = (event) => {
        if (typeof this.props.onChange === 'function') {
            let value = event.target.value;
            if (value !== '' && this.props.type === 'number') {
                value = parseFloat(value);
            }
            this.props.onChange.call(this, value);
        }
    }

    render() {
        return <>
            <label className="hell--input-label" htmlFor={this.props.name}>{this.props.label}</label>
            <br />
            <input className="hell--input" type="text" value={this.props.value} name={this.props.name} onChange={this.handleChange} />
        </>
    }
}

export default HellInput;