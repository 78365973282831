import React, { Component } from 'react';

class ButtonWithPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    onUnderlayerClick = () => {
        this.setState({ visible: false });
    }

    togglePopup = () => {
        this.setState({ visible: true });
    }

    render() {
        return <>
            <button className="button" onClick={this.togglePopup}>{this.props.buttonLabel}</button>
            {this.state.visible ?
                <div className={'popup ' + (this.props.className ? this.props.className : '')}>
                    <div className="popup--inner">
                        {this.props.children}
                    </div>
                    <div className="popup--underlayer" onClick={this.onUnderlayerClick}></div>
                </div> : null}
        </>
    }
}

export default ButtonWithPopup;
