import React, { Component } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { CoinFilter, CoinDiffRow, AppGeneralSettingsPopup, IconButton, SidebarSection } from '../components';
import { coinSymbolToHuman, formatPercents, formatPrice, calculatePercentageDiff, showNotification } from '../lib/functions';
import { ConfigContext } from '../context/configurationContext';
import { getFromLocalstorage, saveToLocalStorage } from '../lib/storage';
import config from '../config';

import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import SidebarPortfolio from '../components/SidebarPortfolio';
import SidebarNotices from '../components/SidebarNotices';
import SingleCoinNotices from '../components/SingleCoinNotices';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const client = new WebSocket('wss://pepiczech.cz:3366');
// const client = new WebSocket('ws://localhost:3366');
// const binanceWSClient = new WebSocket('wss://stream.binance.com:9443/ws/!ticker@arr');
const binanceDayData = {};
// let binanceDayDataLagging = {};

class Sockets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: 'NONE',
            coins: {},
            coinApiStatus: 2,
            layouts: getFromLocalstorage('layouts') || {},
            filteredCoins: getFromLocalstorage('filteredCoins') || [],
            klineData: {},
            coinCellIdConfigOpen: '',
            notices: [],
            coinNotices: {},
        };
        this.binanceWSClientUnresponsive = 0;
        this.binanceWSHeartbeat = false;
    }

    static get defaultProps() {
        return {
            className: 'layout',
            cols: { lg: 10, md: 8, sm: 6, xs: 4, xxs: 2 },
            rowHeight: 100
        };
    }

    calcMinMaxIndicator(min, max, price) {
        if (min >= price) {
            return '0%';
        }
        if (max <= price) {
            return '100%';
        }

        let indicator = 0.5;
        let priceBoundary = max - min;
        if (priceBoundary <= 0) {
            indicator = 0.5;
        }
        let currentGain = price - min;
        indicator = String(Math.round((currentGain / priceBoundary) * 10000) / 100) + '%';
        return indicator;
    }

    resetLayout() {
        this.setState({ layouts: {} });
    }

    onLayoutChange(layout, layouts) {
        saveToLocalStorage('layouts', layouts);
        this.setState({ layouts: layouts });
    }

    getFavoriteCoinAsTitle() {
        let coinKey = this.context.favoriteCoin;
        if (!coinKey) {
            return 'Watcher';
        }

        let coinPrice = parseFloat(this.state.coins[coinKey]?.a) || 0;
        return coinSymbolToHuman(coinKey, true) + ' ' + formatPrice(coinPrice);
    }

    maybeNotify() {
        if (this.state.coins) {
            let notificationLimit = parseFloat(this.context.notificationLimit) || 0;
            if (notificationLimit < 1) {
                notificationLimit = 1;
            }
            // let currentDate = new Date();
            Object.keys(this.state.coins).forEach((cKey) => {
                let threeMinDiff = parseFloat(this.state.coins[cKey].d['3']);
                if (threeMinDiff > notificationLimit || (this.context.notificationIncludeNegative && threeMinDiff < -notificationLimit)) {
                    showNotification(coinSymbolToHuman(cKey) + ' ' + formatPercents(threeMinDiff), '', '3minNotif-' + cKey);
                    // showNotification(coinSymbolToHuman(cKey), currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds() + '\n' + '3 minute change by ' + formatPercents(threeMinDiff), '3minNotif-' + cKey);
                }
            });
        }
    }

    componentDidMount() {
        client.onopen = () => {
            // console.log('WebSocket Client Connected');
        };

        client.onmessage = (message) => {
            if (message.data === 'is:your:hearth:beating') {
                this.sendHearthBeat();
            } else {
                const data = JSON.parse(message.data);
                if (data.message === 'LCD') {
                    // coin data
                    this.setState({ message: data.message, coins: data.coins, coinApiStatus: data.coinApiStatus });
                    // notifications
                    if (this.context.notificationsEnabled) {
                        this.maybeNotify();
                    }
                } else if (data.message === 'MKD') {
                    // candle data
                    this.setState({ klines: data.klineData });
                } else if (data.message === 'ATN') {
                    // Abnormal Trading Notices
                    if (!data.data) {
                        return;
                    }

                    const coinSymbol = data.data.s || '';
                    const coinQuotaAsset = data.data.a || '';
                    if (!coinSymbol || coinSymbol === '' || !coinQuotaAsset || coinQuotaAsset === '') {
                        // Invalid data
                        return;
                    }

                    if (this.context.noticesShowOnlyUsdt && coinQuotaAsset !== 'USDT') {
                        // Skip non USDT pairs
                        return;
                    }
                    
                    let newCoinNotices = { ...this.state.coinNotices };
                    let newNotices = [...this.state.notices];
                    
                    // ADD info to specific coins
                    if (coinSymbol && coinSymbol !== '') {
                        let singleCoinNotices = newCoinNotices[coinSymbol] && Array.isArray(newCoinNotices[coinSymbol]) ? newCoinNotices[coinSymbol] : [];
                        singleCoinNotices.push(data.data);
                        if (singleCoinNotices.length > 5) {
                            singleCoinNotices.shift();
                        }
                        newCoinNotices[coinSymbol] = singleCoinNotices;
                    }

                    // Add notice to main 
                    if (newNotices.length > config.maximumNotices) {
                        // remove first element
                        newNotices.shift();
                    }
                    newNotices.push(data.data)

                    // Change state
                    this.setState({ coinNotices: newCoinNotices, notices: newNotices });
                } else if (data.message === 'ATNB') {
                    // Abnormal Trading Notices - Buffer on connection
                    if (!data.data) {
                        return;
                    }
                    this.setState({ notices: data.data });
                } else if (data.message === 'ATNC') {
                    // Abnormal Trading Notices - Coins on connection
                    if (!data.data) {
                        return;
                    }
                    const initialCOintNotices = {};
                    Object.keys(data.data).forEach((cSymbol) => {
                        if (data.data[cSymbol] && Array.isArray(data.data[cSymbol])) {
                            initialCOintNotices[cSymbol] = data.data[cSymbol];
                        }
                    })
                    this.setState({ coinNotices: initialCOintNotices });
                }
            }
        };

        // binanceWSClient.onmessage = (message) => {
        //     if (typeof message == 'undefined') {
        //         return;
        //     }
        //     let parsedData = JSON.parse(message.data);
        //     if (Array.isArray(parsedData)) {
        //         // binanceDayDataLagging = { ...binanceDayData };
        //         parsedData.forEach((bCoinDayData) => {
        //             binanceDayData[bCoinDayData.s] = bCoinDayData;
        //         });
        //     }
        //     this.binanceWSHeartbeat = true;
        // }

    }

    componentDidUpdate() {
        // update document title
        document.title = this.getFavoriteCoinAsTitle();
    }

    componentWillUnmount() {
        if (client) {
            client.close();
        }
    }

    sendHearthBeat() {
        client.send('my:hearth:is:beating');
    }

    getDiffListRowCount() {
        let count = 0;
        if (this.context.coinCellShowDiff30s) {
            count += 1;
        }
        if (this.context.coinCellShowDiff1m) {
            count += 1;
        }
        if (this.context.coinCellShowDiff2m) {
            count += 1;
        }
        if (this.context.coinCellShowDiff3m) {
            count += 1;
        }
        if (this.context.coinCellShowDiff5m) {
            count += 1;
        }
        if (this.context.coinCellShowDiff15m) {
            count += 1;
        }
        if (this.context.coinCellShowDiff30m) {
            count += 1;
        }
        if (this.context.coinCellShowDiff60m) {
            count += 1;
        }
        return count;
    }

    getCoinCell(id, i) {
        if (!this.state.coins[id] || !this.state.filteredCoins.includes(id)) {
            return null;
        }

        let diff_30s = parseFloat(this.state.coins[id].d['30s']);
        let diff_1 = parseFloat(this.state.coins[id].d['1']);
        let diff_2 = parseFloat(this.state.coins[id].d['2']);
        let diff_3 = parseFloat(this.state.coins[id].d['3']);
        let diff_5 = parseFloat(this.state.coins[id].d['5']);
        let diff_15 = parseFloat(this.state.coins[id].d['15']);
        let diff_30 = parseFloat(this.state.coins[id].d['30']);
        let diff_60 = parseFloat(this.state.coins[id].d['60']);

        var strength = Math.round((((3 * diff_30s) + (3 * diff_1) + (2 * diff_3) + diff_5) / 9) * 100) / 100;
        let strength_direction_up = strength >= 0 ? true : false;
        strength = Math.abs(strength);

        var arrowNumber = 1;
        if (strength >= 20) {
            arrowNumber = 5;
        } else if (strength >= 15) {
            arrowNumber = 4;
        } else if (strength >= 10) {
            arrowNumber = 3;
        } else if (strength >= 5) {
            arrowNumber = 2;
        }

        if (strength < 0) {
            strength = 0;
        } else if (strength > 1) {
            strength = 1;
        }

        // calculate hourly min/max pos
        let coinMin = parseFloat(this.state.coins[id].mn) || 0;
        let coinMax = parseFloat(this.state.coins[id].mx) || 0;
        let coinPrice = parseFloat(this.state.coins[id].a) || 0;
        let minmaxIndicatorPosition = 0.5;
        if (coinMin > 0 && coinMax > 0 && coinPrice > 0) {
            minmaxIndicatorPosition = this.calcMinMaxIndicator(coinMin, coinMax, coinPrice);
        }

        let cellLayoutData = {
            w: 0,
            h: 0
        };
        if (this.state.layouts && this.state.layouts.lg) {
            let possibleCellLayoutData = this.state.layouts.lg.find(element => element.i === id);
            if (possibleCellLayoutData) {
                cellLayoutData = possibleCellLayoutData;
            }
        }
        let minMaxHourlyPercentage = calculatePercentageDiff(coinMin, coinMax);

        // calculate day min/max pos
        let coinDailyMin = parseFloat(this.state.coins[id].dl || 0);
        let coinDailyMax = parseFloat(this.state.coins[id].dh || 0);
        let minMaxDailyIndicatiorPos = 0.5;
        if (coinDailyMin > 0 && coinDailyMax > 0 && coinPrice > 0) {
            minMaxDailyIndicatiorPos = this.calcMinMaxIndicator(coinDailyMin, coinDailyMax, coinPrice);
        }
        let minMaxDailyPercentage = calculatePercentageDiff(coinDailyMin, coinDailyMax);

        var cellTheme = 'default';
        var cellLayoutWidth = parseInt(cellLayoutData.w || 0);
        var cellLayoutHeight = parseInt(cellLayoutData.h || 0);
        if (cellLayoutWidth >= 3 && cellLayoutHeight >= 4) {
            cellTheme = 'xl';
        } else if (cellLayoutWidth >= 2 && cellLayoutHeight >= 3) {
            cellTheme = 'lg';
        }

        // calculate hourly min/max position in relation to daily min/max
        let minMaxDailyIndicatorHourlyMaxPos = 0.5;
        let minMaxDailyIndicatorHourlyMinPos = 0.5;
        if (coinDailyMin > 0 && coinDailyMax > 0) {
            if (coinMin > 0) {
                minMaxDailyIndicatorHourlyMinPos = this.calcMinMaxIndicator(coinDailyMin, coinDailyMax, coinMin);
            }
            if (coinMax > 0) {
                minMaxDailyIndicatorHourlyMaxPos = this.calcMinMaxIndicator(coinDailyMin, coinDailyMax, coinMax);
            }
        }

        // determine 24h trend
        let dailyPercentageChange = parseFloat(this.state.coins[id].dP || 0);
        let dailyPercentageTrend = dailyPercentageChange >= 0 ? 'u' : 'd';

        const coinName = coinSymbolToHuman(id, true);

        return <div key={id} data-grid={{ x: Math.floor(i / 25), y: 0, w: 1, h: 3, minW: 1, minH: 2 }}>
            <div className={'coin-cell' + (this.context.coinCellAddHourlyMinMaxToDailyIndicator ? ' coin-cell_combinedMXIndicator' : '')} style={{ '--hell-cell-strenght': strength }} data-strength-dir={strength_direction_up ? 'u' : 'd'} data-arrows={arrowNumber} data-cell-heigh={cellLayoutHeight} data-cell-width={cellLayoutWidth} data-cell-theme={cellTheme}>
                <div className="coin-cell--name"><a className='color-orange' rel="noreferrer" href={`https://www.binance.com/en/trade/${coinName}_USDT?layout=pro`} target='_blank'>{coinSymbolToHuman(id)}</a></div>
                <div className="coin-cell--price">{formatPrice(coinPrice)}</div>
                <div className="coin-cell--minmax">
                    {this.context.coinCellShowDailyMinMax ?
                        <div className="coin-cell--mimax--values coin-cell--daily-mimax--values">
                            <span className="color-red">{formatPrice(this.state.coins[id].dl || 0)}</span>
                            <span className="color-white"> D </span>
                            <span className="color-green">{formatPrice(this.state.coins[id].dh || 0)}</span>
                        </div> : null}
                    {this.context.coinCellShowDailyMinMaxIndicator ?
                        <div className="coin-cell--minmax--indicator coin-cell--daily-minmax--indicator" style={{ '--hell-data-ball-position': minMaxDailyIndicatiorPos, '--hell-data-hmax-ball-position': minMaxDailyIndicatorHourlyMaxPos, '--hell-data-hmin-ball-position': minMaxDailyIndicatorHourlyMinPos }}>
                            <div className="coin-cell--minmax--indicator--ball coin-cell--minmax--indicator--ball--hourly-min" />
                            <div className="coin-cell--minmax--indicator--ball coin-cell--minmax--indicator--ball--hourly-max" />
                            <div className="coin-cell--minmax--indicator--ball coin-cell--minmax--indicator--ball--main" />
                        </div> : null}
                    {this.context.coinCellShowHourlyMinMax ?
                        <div className="coin-cell--mimax--values coin-cell--hourly-mimax--values">
                            <span className="color-red">{formatPrice(coinMin)}</span>
                            <span className="color-white"> H </span>
                            <span className="color-green">{formatPrice(coinMax)}</span>
                        </div> : null}
                    {this.context.coinCellShowHourlyMinMaxIndicator ?
                        <div className="coin-cell--minmax--indicator coin-cell--hourly-minmax--indicator" style={{ '--hell-data-ball-position': minmaxIndicatorPosition }}>
                            <div className="coin-cell--minmax--indicator--ball coin-cell--minmax--indicator--ball--main" />
                        </div> : null}
                </div>
                <div className="coin-cell--volumes">
                    {this.context.coinCellShowDailyMinMaxPerc ? <>
                        <div className="coin-cell--volume--minmax">
                            <span>M/X D%</span>
                            <span>{formatPercents(minMaxDailyPercentage)}</span>
                        </div>
                        <div className="coin-cell--volume--minmax">
                            <span>M/X H%</span>
                            <span>{formatPercents(minMaxHourlyPercentage)}</span>
                        </div>
                    </> : null}
                    {this.context.coinCellShowDailyVolumeCoins ?
                        <div className="coin-cell--volume coin-cell--volume--long-row"><span>{String(id).replace('USDT', '')}</span><span>{formatPrice(this.state.coins[id].dv || 0, true, 2)}</span></div> : null}
                    {this.context.coinCellShowDailyVolumeUsdt ?
                        <div className="coin-cell--volume coin-cell--volume--long-row"><span>USDT</span><span>{formatPrice(this.state.coins[id].dq || 0, true, 2)}</span></div> : null}
                    {this.context.coinCellShowDailyVolumeChange ?
                        <div className="coin-cell--volume"><span>24h diff</span><span className="trend-color" data-trend={dailyPercentageTrend}>{formatPrice(this.state.coins[id].p || 0)}</span></div> : null}
                    {this.context.coinCellShowDailyVolumeChangePerc ?
                        <div className="coin-cell--volume"><span>24h %</span><span className="trend-color" data-trend={dailyPercentageTrend}>{formatPercents(dailyPercentageChange)}</span></div> : null}
                </div>
                <div className="coin-cell--diff-wrap coin-cell--diff-wrap-1351530">
                    {this.context.coinCellShowDiff30s ? <CoinDiffRow trend={diff_30s} label="30s" /> : null}
                    {this.context.coinCellShowDiff1m ? <CoinDiffRow trend={diff_1} label="1m" /> : null}
                    {this.context.coinCellShowDiff2m ? <CoinDiffRow trend={diff_2} label="2m" /> : null}
                    {this.context.coinCellShowDiff3m ? <CoinDiffRow trend={diff_3} label="3m" /> : null}
                    {this.context.coinCellShowDiff5m ? <CoinDiffRow trend={diff_5} label="5m" /> : null}
                    {this.context.coinCellShowDiff15m ? <CoinDiffRow trend={diff_15} label="15m" /> : null}
                    {this.context.coinCellShowDiff30m ? <CoinDiffRow trend={diff_30} label="30m" /> : null}
                    {this.context.coinCellShowDiff60m ? <CoinDiffRow trend={diff_60} label="60m" /> : null}
                </div>
                {this.context.noticesShowCoinCell && this.state.coinNotices[id] ? <SingleCoinNotices notices={this.state.coinNotices[id]} /> : null}
            </div>
            <div className="coin-cell--actions">
                <IconButton className="coin-cell-remove-button" icon="trash" onClick={() => { this.onCoinFiltered(id) }} />
            </div>
        </div>
    };

    getToolbarGainerElement(name, value) {
        return <div className="toolbar--gainers--gainer" data-trend={value < 0 ? 'n' : 'p'}><span className="toolbar--gainer--name">{name}</span> <span className="toolbar--gainer--name">{value}%</span></div>
    }

    onCoinFiltered = (coinToToggle) => {
        let fCoins = this.state.filteredCoins;
        if (fCoins.includes(coinToToggle)) {
            fCoins = fCoins.filter((item) => {
                return item !== coinToToggle;
            });
        } else {
            fCoins.push(coinToToggle);
        }
        saveToLocalStorage('filteredCoins', fCoins);
        this.setState({ filteredCoins: fCoins });
    }

    addCoinToFiltered = (coinToAdd) => {
        let fCoins = this.state.filteredCoins;
        if (!fCoins.includes(coinToAdd)) {
            fCoins.push(coinToAdd);
            saveToLocalStorage('filteredCoins', fCoins);
            this.setState({ filteredCoins: fCoins });
        }
    }

    calculateAllCoinOrderables = (dataCoins, dataBinance, klineData) => {
        const dataResult = {
            '30s': [],
            '1': [],
            '2': [],
            '3': [],
            '5': [],
            '15': [],
            '30': [],
            '60': [],
            'B24h': [],
            'BDVol': [],
            'BDMX': [],
            'BHMX': []
        };

        Object.keys(dataCoins).forEach((dataCoinSymbol) => {
            const currentCoinPrice = parseFloat(dataCoins[dataCoinSymbol].a) || 0;
            dataResult['30s'].push([dataCoinSymbol, dataCoins[dataCoinSymbol].d['30s'], currentCoinPrice]);
            dataResult['1'].push([dataCoinSymbol, dataCoins[dataCoinSymbol].d['1'], currentCoinPrice]);
            dataResult['2'].push([dataCoinSymbol, dataCoins[dataCoinSymbol].d['2'], currentCoinPrice]);
            dataResult['3'].push([dataCoinSymbol, dataCoins[dataCoinSymbol].d['3'], currentCoinPrice]);
            dataResult['5'].push([dataCoinSymbol, dataCoins[dataCoinSymbol].d['5'], currentCoinPrice]);
            dataResult['15'].push([dataCoinSymbol, dataCoins[dataCoinSymbol].d['15'], currentCoinPrice]);
            dataResult['30'].push([dataCoinSymbol, dataCoins[dataCoinSymbol].d['30'], currentCoinPrice]);
            dataResult['60'].push([dataCoinSymbol, dataCoins[dataCoinSymbol].d['60'], currentCoinPrice]);

            dataResult['B24h'].push([dataCoinSymbol, parseFloat(dataCoins[dataCoinSymbol].dP || 0), currentCoinPrice]);

            // volume change ( data options v=1mVolume 1=1mVS2m, 2=2mVS3m, 3=3mVS4m)
            let klineVolChange = 0.0;
            let klineTrend = 'u';
            let klineVolume = 0.0;
            // let klineVolumeDolla = 0.0;
            if (klineData && klineData[dataCoinSymbol]) {
                klineVolChange = (parseFloat(klineData[dataCoinSymbol]['1'][0] || 0) + parseFloat(klineData[dataCoinSymbol]['2'][0] || 0) + parseFloat(klineData[dataCoinSymbol]['3'][0] || 0)) / 3;
                klineTrend = klineData[dataCoinSymbol]['1'][1];
                klineVolume = parseFloat(klineData[dataCoinSymbol].v) || 0;
                // klineVolumeDolla = parseFloat(klineData[dataCoinSymbol].vc) || 0;
            }
            dataResult['BDVol'].push([dataCoinSymbol, klineVolChange, klineVolume, klineTrend]);

            /*
            let bddl = parseFloat(binanceDayDataLagging[dataCoinSymbol]?.v || 0);
            let bdd = parseFloat(dataBinance[dataCoinSymbol]?.v || 0);
            dataResult['BDVol'].push([dataCoinSymbol, calculatePercentageDiff(bddl, bdd)]);
            */

            // calculate hourly min/max pos
            let coinMin = parseFloat(dataCoins[dataCoinSymbol].mn) || 0;
            let coinMax = parseFloat(dataCoins[dataCoinSymbol].mx) || 0;
            let minMaxHourlyPercentage = calculatePercentageDiff(coinMin, coinMax);

            // calculate day min/max pos
            let coinDailyMin = parseFloat(dataCoins[dataCoinSymbol].dl || 0);
            let coinDailyMax = parseFloat(dataCoins[dataCoinSymbol].dh || 0);
            let minMaxDailyPercentage = calculatePercentageDiff(coinDailyMin, coinDailyMax);

            dataResult['BDMX'].push([dataCoinSymbol, minMaxDailyPercentage, currentCoinPrice]);
            dataResult['BHMX'].push([dataCoinSymbol, minMaxHourlyPercentage, currentCoinPrice]);
        });

        Object.keys(dataResult).forEach((drId) => {
            if (drId === 'BDVol') {
                // filter out low volumes and negative volume shifts
                dataResult[drId] = dataResult[drId].filter(vVal => {
                    let coinPrice = dataCoins[vVal[0]]?.a || 0;
                    return vVal[2] * coinPrice > 100000 && vVal[1] > 0;
                });
            }
            // sort
            dataResult[drId].sort((a, b) => {
                return a[1] - b[1];
            });
        });
        return dataResult;
    }

    render() {
        let diffRowCount = this.getDiffListRowCount();
        let cointData = Object.keys(this.state.coins).map((coinKey, i) => {
            return this.getCoinCell(coinKey, i);
        });
        let calcOrderables = this.calculateAllCoinOrderables(this.state.coins, binanceDayData, this.state.klines);
        let wrapDivClassName = '';
        if (this.context.priceChangeChartsIncludeCoinPrice) {
            wrapDivClassName += 'price-change-with-coin-price';
        }

        var gridContent;
        if (cointData.length > 0) {
            gridContent =
                <ResponsiveReactGridLayout className={'layout' + (this.context.coinCellTrendArrowVisible ? ' theme_trend_icon_visible' : '')} cols={{ lg: this.context.coinCellColumns, md: 8, sm: 6, xs: 4, xxs: 2 }} rowHeight={100} layouts={this.state.layouts} onLayoutChange={(layout, layouts) => this.onLayoutChange(layout, layouts)} compactType="vertical">
                    {cointData}
                </ResponsiveReactGridLayout>;
        }

        return (
            <div className={wrapDivClassName}>
                <div className="toolbar">
                    <AppGeneralSettingsPopup coins={this.state.coins} />
                    <CoinFilter coins={this.state.coins} onCoinFiltered={this.onCoinFiltered} filteredCoins={this.state.filteredCoins} />
                    {this.context.toolbarShowGainers ?
                        <div className="toolbar--gainers">
                            <SidebarSection onCoinClick={this.addCoinToFiltered} orderBy={this.context.toolbarGainersOrderBy} limit={this.context.toolbarGainersLimit} orderedData={calcOrderables} showHeadline={false} ignoreCoinPrice />
                        </div> : null}
                    <div className="toolbar--coin-api-status" data-status={this.state.coinApiStatus}></div>
                </div>
                <div className="main" data-diff-row-count={diffRowCount}>
                    {this.context.noticesShowSidebar ?
                        <div className="main--sidebar">
                            <SidebarNotices notices={this.state.notices} />
                        </div> : null}
                    <div className="main--content">
                        {gridContent}
                    </div>
                    <div className="main--sidebar">
                        <SidebarPortfolio coins={this.state.coins} />
                        {this.context.sidebarGainersShowSectionA ? <SidebarSection onCoinClick={this.addCoinToFiltered} orderBy={this.context.sidebarGainrserOrderBy} limit={this.context.sidebarGainersNumber} orderedData={calcOrderables} showHeadline /> : null}
                        {this.context.sidebarGainersShowSectionB ? <SidebarSection onCoinClick={this.addCoinToFiltered} orderBy={this.context.sidebarScndGainersOrderBy} limit={this.context.sidebarGainersNumber} orderedData={calcOrderables} showHeadline /> : null}
                        {this.context.sidebarGainersShowSectionC ? <SidebarSection onCoinClick={this.addCoinToFiltered} orderBy={this.context.sidebarThirdGainersOrderBy} limit={this.context.sidebarGainersNumber} orderedData={calcOrderables} showHeadline /> : null}
                        {this.context.sidebarGainersShowSectionD ? <SidebarSection onCoinClick={this.addCoinToFiltered} orderBy={this.context.sidebarForthGainersOrderBy} limit={this.context.sidebarGainersNumber} orderedData={calcOrderables} showHeadline /> : null}
                    </div>
                </div>
            </div>
        );
    }
}

Sockets.contextType = ConfigContext;

export default Sockets;