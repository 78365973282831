import React, { Component } from 'react';
import { ConfigContext } from '../context/configurationContext';
import SidebarNoticeRow from './SidebarNoticeRow';

class SidebarNotices extends Component {
    render() {
        let maximumNotices = parseInt(this.context.noticesVisibleCount) || 25;
        if (maximumNotices <= 0 || maximumNotices > 50) {
            maximumNotices = 25;
        }
        let notices = this.props.notices && Array.isArray(this.props.notices) ? [...this.props.notices] : [];
        notices.reverse();
        notices = notices.slice(0, maximumNotices);
        return <div className="sidebar-notices">
            <div className='sidebar-notices--content text-size--sm'>
                {notices.map((notice, i) => <SidebarNoticeRow notice={notice} key={`snr${i}`} />)}
            </div>
        </div>
    }
}

SidebarNotices.contextType = ConfigContext;

export default SidebarNotices;