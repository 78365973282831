import React, { Component } from 'react';
import Sockets from './web_sockets';
import { ConfigContext } from './context/configurationContext';
import { getFromLocalstorage, saveToLocalStorage } from './lib/storage';

class App extends Component {
  constructor(props) {
    super(props);

    this.changeConfig = (name, value) => {
      saveToLocalStorage(name, value);
      this.setState(state => ({
        [name]: value
      }));
    };

    this.toggleConfig = (name) => {
      saveToLocalStorage(name, !this.state[name]);
      this.setState(state => ({
        [name]: !state[name]
      }));
    }

    this.getValue = (name, defaultValue) => {
      let value = getFromLocalstorage(name);
      if (typeof value == 'undefined') {
        return defaultValue;
      }
      return value;
    }

    this.toggleNotifications = () => {
      // ask for notification permission
      // when permissions not asked yet and previous state is false
      if (Notification.permission === 'default' && !this.state.notificationsEnabled) {
        Notification.requestPermission().then(result => {
          saveToLocalStorage('notificationsEnabled', false);
          this.setState(state => ({
            notificationsEnabled: false
          }));
        });
      }

      saveToLocalStorage('notificationsEnabled', !this.state.notificationsEnabled);
      this.setState(state => ({
        notificationsEnabled: !state.notificationsEnabled
      }));
    }

    this.state = {
      toolbarShowGainers: this.getValue('toolbarShowGainers', false),
      filterShowPrice: this.getValue('filterShowPrice', false),
      filterOrderByPrice: this.getValue('filterOrderByPrice', false),
      sidebarGainersCount: parseInt(this.getValue('sidebarGainersCount')) || 10,
      coinCellColumns: parseInt(this.getValue('coinCellColumns')) || 10,
      coinCellTrendArrowVisible: this.getValue('coinCellTrendArrowVisible', true),
      coinCellShowDiff30s: this.getValue('coinCellShowDiff30s', false),
      coinCellShowDiff1m: this.getValue('coinCellShowDiff1m', true),
      coinCellShowDiff2m: this.getValue('coinCellShowDiff2m', false),
      coinCellShowDiff3m: this.getValue('coinCellShowDiff3m', true),
      coinCellShowDiff5m: this.getValue('coinCellShowDiff5m', true),
      coinCellShowDiff15m: this.getValue('coinCellShowDiff15m', true),
      coinCellShowDiff30m: this.getValue('coinCellShowDiff30m', true),
      coinCellShowDiff60m: this.getValue('coinCellShowDiff60m', false),
      coinCellShowDailyMinMaxPerc: this.getValue('coinCellShowDailyMinMaxPerc', false),
      coinCellShowDailyMinMax: this.getValue('coinCellShowDailyMinMax', true),
      coinCellShowDailyMinMaxIndicator: this.getValue('coinCellShowDailyMinMaxIndicator', true),
      coinCellShowHourlyMinMax: this.getValue('coinCellShowHourlyMinMax', true),
      coinCellShowHourlyMinMaxIndicator: this.getValue('coinCellShowHourlyMinMaxIndicator', true),
      coinCellAddHourlyMinMaxToDailyIndicator: this.getValue('coinCellAddHourlyMinMaxToDailyIndicator', false),
      coinCellShowDailyVolumeCoins: this.getValue('coinCellShowDailyVolumeCoins', false),
      coinCellShowDailyVolumeUsdt: this.getValue('coinCellShowDailyVolumeUsdt', true),
      coinCellShowDailyVolumeChange: this.getValue('coinCellShowDailyVolumeChange', false),
      coinCellShowDailyVolumeChangePerc: this.getValue('coinCellShowDailyVolumeChangePerc', true),
      sidebarGainersNumber: this.getValue('sidebarGainersNumber', 10),
      sidebarGainersShowSectionA: this.getValue('sidebarGainersShowSectionA', true),
      sidebarGainrserOrderBy: this.getValue('sidebarGainrserOrderBy', '60m'),
      sidebarGainersShowSectionB: this.getValue('sidebarGainersShowSectionB', false),
      sidebarScndGainersOrderBy: this.getValue('sidebarScndGainersOrderBy', '60m'),
      sidebarGainersShowSectionC: this.getValue('sidebarGainersShowSectionC', false),
      sidebarThirdGainersOrderBy: this.getValue('sidebarThirdGainersOrderBy', '60m'),
      sidebarGainersShowSectionD: this.getValue('sidebarGainersShowSectionD', false),
      sidebarForthGainersOrderBy: this.getValue('sidebarForthGainersOrderBy', '60m'),
      toolbarGainersLimit: this.getValue('toolbarGainersLimit', 2),
      toolbarGainersOrderBy: this.getValue('toolbarGainersOrderBy', '60m'),
      favoriteCoin: this.getValue('favoriteCoin', 'BTCUSDT'),
      notificationsEnabled: this.getValue('notificationsEnabled', false),
      notificationLimit: this.getValue('notificationLimit', 2),
      notificationIncludeNegative: this.getValue('notificationIncludeNegative', false),
      noticesShowSidebar: this.getValue('noticesShowSidebar', true),
      noticesVisibleCount: this.getValue('notificationLimit', 25),
      noticesShowCoinCell: this.getValue('noticesShowCoinCell', true),
      noticesShowOnlyUsdt: this.getValue('noticesShowOnlyUsdt', false),
      reversePriceChangeCharts: this.getValue('reversePriceChangeCharts', false),
      priceChangeChartsIncludeCoinPrice: this.getValue('priceChangeChartsIncludeCoinPrice', false),
      sidebarShowPortfolio: this.getValue('sidebarShowPortfolio', false),
      changeConfig: this.changeConfig,
      toggleConfig: this.toggleConfig,
      toggleNotifications: this.toggleNotifications,
    };
  }

  render() {
    return (
      <div className="App">
        <ConfigContext.Provider value={this.state}>
          <Sockets />
        </ConfigContext.Provider>
      </div>
    );
  }
}

export default App;