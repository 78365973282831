import React, { Component } from 'react';
import { coinSymbolToHuman, formatPrice } from '../lib/functions';
import { HellToggle } from './inputs';
import ButtonWithPopup from './buttonWithPopup';
import { ConfigContext } from '../context/configurationContext';

class CoinFilterItem extends Component {
    render() {
        return <div className={'coin-filter--item' + (this.props.showPrice ? ' coin-filter--item__visible_price' : '')}>
            <label><input type="checkbox" checked={this.props.isActive} onChange={() => { this.props.onChange(this.props.name) }} /> <span>{coinSymbolToHuman(this.props.name)}</span><span className="coin-filter--item--label--price"> ({formatPrice(this.props.price) || ''})</span></label>
        </div>
    }
}

class CoinFilter extends Component {
    onShowPriceClick = () => {
        this.context.toggleConfig('filterShowPrice');
    }

    onOrderByPriceClick = () => {
        this.context.toggleConfig('filterOrderByPrice');
    }

    render() {
        var filterOptions = [];
        let coinData = this.props.coins;
        var coinKeys = Object.keys(coinData);
        if (this.context.filterOrderByPrice) {
            coinKeys.sort((elA, elB) => {
                if (coinData[elA].a > coinData[elB].a) {
                    return -1
                } else if (coinData[elA].a < coinData[elB].a) {
                    return 1
                }
                return 0;
            });
        } else {
            coinKeys.sort();
        }
        coinKeys.forEach((coinSymbol) => {
            filterOptions.push(<CoinFilterItem key={'CFI-' + coinSymbol} name={coinSymbol} isActive={this.props.filteredCoins.includes(coinSymbol)} onChange={this.props.onCoinFiltered} showPrice={this.context.filterShowPrice} price={coinData[coinSymbol].a} />);
        });
        return <ButtonWithPopup buttonLabel="Filter" className="popup--coin-filter">
            <div className="popup--coin-filter--toolbar">
                <HellToggle name="show-price" label="Toggle price" onChange={this.onShowPriceClick} isActive={this.context.filterShowPrice} />
                <HellToggle name="order-by-price" label="Order by price" onChange={this.onOrderByPriceClick} isActive={this.context.filterOrderByPrice} />
            </div>
            <div className={'popup--coin-filter--items' + (this.context.filterShowPrice ? ' popup--coin-filter--items__price_visible' : '')}>{filterOptions}</div>
        </ButtonWithPopup>
    }
}

CoinFilter.contextType = ConfigContext;

export default CoinFilter;
