import React, { Component } from 'react';
import ToolbarGainer from './toolbarGainer';
import { ConfigContext } from '../context/configurationContext';
import { coinSymbolToHuman } from '../lib/functions';

class SidebarSection extends Component {

    getGainersType = (orderBy) => {
        switch (orderBy) {
            case '30s':
                return '30s';
            case '1m':
                return '1';
            case '2m':
                return '2';
            case '3m':
                return '3';
            case '5m':
                return '5';
            case '15m':
                return '15';
            case '30m':
                return '30';
            case '60m':
                return '60';
            case '24h':
                return 'B24h';
            case 'dailyVolume':
                return 'BDVol';
            case 'dailyMX':
                return 'BDMX';
            case 'hourlyMX':
                return 'BHMX';
            default:
                return '60';
        }
    }

    getHeader = (orderBy) => {
        switch (orderBy) {
            case '30s':
                return 'Price % change - 30 sec';
            case '1m':
                return 'Price % change - 1 min';
            case '2m':
                return 'Price % change - 2 min';
            case '3m':
                return 'Price % change - 3 min';
            case '5m':
                return 'Price % change - 5 min';
            case '15m':
                return 'Price % change - 15 min';
            case '30m':
                return 'Price % change - 30 min';
            case '60m':
                return 'Price % change - 1 hour';
            case '24h':
                return 'Price % change - 1 day';
            case 'dailyVolume':
                return 'Volume Shift';
            case 'dailyMX':
                return 'Daily M/X Range';
            case 'hourlyMX':
                return 'Hourly M/X Range';
            default:
                return '1 hour';
        }
    }

    render() {
        let orderBy = this.props.orderBy || '60m';
        let type = this.getGainersType(orderBy);
        let coinsToShow = parseInt(this.props.limit) || 5;
        let reverseOder = this.context.reversePriceChangeCharts ?? false;
        let includeCoinPrice = this.context.priceChangeChartsIncludeCoinPrice ?? false;

        if (!this.props.orderedData || !this.props.orderedData[type]) {
            return null;
        }

        if (this.props.ignoreCoinPrice || type === 'BDVol') {
            includeCoinPrice = false;
        }

        // get top coins
        let topCoins = [];
        this.props.orderedData[type].slice(-coinsToShow).forEach((gain, gi) => {
            let currentCoinPrice = gain[2] || '';
            topCoins.push(<ToolbarGainer key={'tbGainerB' + gi} name={coinSymbolToHuman(gain[0])} value={gain[1]} trend={gain[3] || ''} coinSymbol={gain[0]} onClick={this.props.onCoinClick} includeCoinPrice={includeCoinPrice} coinPrice={currentCoinPrice} />);
        });

        // get bottom coins
        let bottomCoins = [];
        if (type !== 'BDMX' && type !== 'BHMX' && type !== 'BDVol') {
            this.props.orderedData[type].slice(0, coinsToShow).forEach((gain, gi) => {
                let currentCoinPrice = gain[2] || '';
                bottomCoins.push(<ToolbarGainer key={'tbGainerA' + gi} name={coinSymbolToHuman(gain[0])} value={gain[1]} trend={gain[3] || ''} coinSymbol={gain[0]} onClick={this.props.onCoinClick} includeCoinPrice={includeCoinPrice} coinPrice={currentCoinPrice} />);
            });
        }

        if (type === 'BDMX' || type === 'BHMX' || type === 'BDVol') {
            topCoins.reverse();
        } else if (reverseOder) {
            topCoins.reverse();
            bottomCoins.reverse();
        }

        return <div className="main--sidebar--section">
            {this.props.showHeadline !== false ?
                <h4 className="main--sidebar--title">{this.getHeader(orderBy)}</h4> : null}
            <div className="main--sidebar--section main--sidebar--gainers">{topCoins}</div>
            <div className="main--sidebar--section main--sidebar--loosers">{bottomCoins}</div>
        </div>
    }
}

SidebarSection.contextType = ConfigContext;

export default SidebarSection;