import React, { Component } from 'react';

class NoticeIcon extends Component {
    render() {
        if (this.props.icon === 'candle-down') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M11.4 13.507h1.2V11.5H14v-7h-1.4V2.493h-1.2v2.006H10v7h1.4v2.008zM19 13.507l3-3.75h-2.4V2.493h-1.2v7.264H16l3 3.75z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'candle-down2') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M7.9 13.507h1.2V11.5h1.4v-7H9.1V2.493H7.9v2.006H6.5v7h1.4v2.008zM18.5 9.757l-3 3.75-3-3.75h2.4V2.493h1.2v7.264h2.4zM25.5 9.757l-3 3.75-3-3.75h2.4V2.493h1.2v7.264h2.4z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'candle-down3') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M4.4 13.507h1.2V11.5H7v-7H5.6V2.493H4.4v2.006H3v7h1.4v2.008zM12 13.507l3-3.75h-2.4V2.493h-1.2v7.264H9l3 3.75zM19 13.507l3-3.75h-2.4V2.493h-1.2v7.264H16l3 3.75zM29 9.757l-3 3.75-3-3.75h2.4V2.493h1.2v7.264H29z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'candle-up') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M11.4 2.493h1.2V4.5H14v7h-1.4v2.006h-1.2v-2.006H10v-7h1.4V2.493zM19 2.493l3 3.75h-2.4v7.264h-1.2V6.243H16l3-3.75z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'candle-up2') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M7.9 2.493h1.2V4.5h1.4v7H9.1v2.006H7.9v-2.006H6.5v-7h1.4V2.493zM18.5 6.243l-3-3.75-3 3.75h2.4v7.264h1.2V6.243h2.4zM25.5 6.243l-3-3.75-3 3.75h2.4v7.264h1.2V6.243h2.4z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'candle-up3') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M4.4 2.493h1.2V4.5H7v7H5.6v2.006H4.4v-2.006H3v-7h1.4V2.493zM12 2.493l3 3.75h-2.4v7.264h-1.2V6.243H9l3-3.75zM19 2.493l3 3.75h-2.4v7.264h-1.2V6.243H16l3-3.75zM29 6.243l-3-3.75-3 3.75h2.4v7.264h1.2V6.243H29z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'down') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M15.077 11.692h4.615V7.077l-1.846 1.846-5.077-5.077-.923.923 5.077 5.077-1.846 1.846z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'down2') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M24.609 11.692h-4.616l1.846-1.846-5.077-5.077.924-.923 5.076 5.077 1.847-1.846v4.615zM15.692 11.692h-4.615l1.846-1.846-5.077-5.077.923-.923 5.077 5.077 1.846-1.846v4.615z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'line-down') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M19.465 6v2.5L16 11l-3.465-2.5V6L16 8.5 19.465 6zM10.02 9.636H3V7.364h7.02v2.272zm18.98 0h-7.02V7.364H29v2.272z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'line-down2') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M19.484 3v2.676L16.02 8.353l-3.465-2.677V3l3.465 2.676L19.484 3z" fill="currentColor"></path><path d="M19.484 7.647v2.676L16.02 13l-3.465-2.676V7.648l3.465 2.676 3.464-2.676zM10.04 9.216H3.02V6.784h7.02v2.432zm18.98 0H22V6.784h7.02v2.432z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'line-down3') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M12.535 2v2.192L16 6.385l3.465-2.192V2L16 4.193 12.535 2z" fill="currentColor"></path><path d="M12.535 5.807V8L16 10.193 19.465 8V5.807L16 8l-3.465-2.193z" fill="currentColor"></path><path d="M12.535 9.615v2.192L16 14l3.465-2.193V9.615L16 11.807l-3.465-2.192zm9.445-2.611H29v1.992h-7.02V7.004zM3 7.004h7.02v1.992H3V7.004z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'line-up') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M12.535 11V8.5L16 6l3.465 2.5V11L16 8.5 12.535 11zm9.445-3.636H29v2.272h-7.02V7.364zM3 7.364h7.02v2.272H3V7.364z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'line-up2') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M12.555 13v-2.676l3.465-2.677 3.465 2.677V13l-3.465-2.676L12.555 13z" fill="currentColor"></path><path d="M12.555 8.352V5.677L16.02 3l3.465 2.676v2.676L16.02 5.676l-3.465 2.676zM22 6.784h7.02v2.432H22V6.784zm-18.98 0h7.02v2.432H3.02V6.784z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'line-up3') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M19.465 14v-2.192L16 9.615l-3.465 2.192V14L16 11.807 19.465 14z" fill="currentColor"></path><path d="M19.465 10.193V8L16 5.807 12.535 8v2.193L16 8l3.465 2.193z" fill="currentColor"></path><path d="M19.465 6.385V4.193L16 2l-3.465 2.192v2.193L16 4.193l3.465 2.192zM10.02 8.996H3V7.004h7.02v1.992zm18.98 0h-7.02V7.004H29v1.992z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'reverse-down') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M19 9h2l-3 3-3-3h2.256V6.388c0-.884-.783-1.602-1.75-1.602-.966 0-1.75.718-1.75 1.602V13H12V6.388C12 4.52 13.569 3 15.5 3 17.432 3 19 4.521 19 6.388V9z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'reverse-up') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M19 9h2l-3-3-3 3h2.256v.612c0 .884-.783 1.602-1.75 1.602-.966 0-1.75-.718-1.75-1.602V3H12v6.612C12 11.48 13.569 13 15.5 13c1.932 0 3.5-1.521 3.5-3.388V9z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'up') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M15.077 4.308h4.615v4.615l-1.846-1.846-5.077 5.077-.923-.923 5.077-5.077-1.846-1.846z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'up2') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M14.692 4.308h-4.615l1.846 1.846-5.077 5.077.923.923 5.077-5.077 1.846 1.846V4.308zM23.609 4.308h-4.616l1.846 1.846-5.077 5.077.924.923 5.076-5.077 1.847 1.846V4.308z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'up3') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path d="M6.077 4.308h4.615v4.615L8.846 7.077 3.77 12.154l-.923-.923 5.077-5.077-1.846-1.846zM14.993 4.308h4.616v4.615l-1.846-1.846-5.077 5.077-.923-.923 5.077-5.077-1.847-1.846zM28.274 4.308h-4.616l1.847 1.846-5.077 5.077.923.923 5.077-5.077 1.846 1.846V4.308z" fill="currentColor"></path></svg>
        }
        if (this.props.icon === 'whale') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" fill="none" width="32" height="16"><path fillRule='evenodd' clipRule='evenodd' d="M23.005 7.4c.059-.298.143-.726-.005-.9-.024-.029-.528-.028-.84-.027h-7.843a1.827 1.827 0 01-1.795-1.484c-.003-.017-.008-.034-.013-.052h.461c.825 0 1.496-.67 1.496-1.495v-.4a.29.29 0 00-.291-.29h-.872c-.64 0-1.223.328-1.569.834a1.903 1.903 0 00-1.568-.835h-.872a.29.29 0 00-.29.291v.4c0 .825.67 1.495 1.495 1.495h.509a.802.802 0 00-.025.196v1.63c0 1.254.469 2.451 1.32 3.37a4.924 4.924 0 002.834 1.522 2.02 2.02 0 00.057 1.423c.03.07.086.127.158.156a.348.348 0 00.221-.001l2.641-1.113a.894.894 0 00.46-.436 4.942 4.942 0 002.912-1.417 4.91 4.91 0 001.386-2.692c.002-.002.002-.005.002-.008.007-.048.018-.105.03-.168zM21.88 4.685a.29.29 0 11-.58 0 .544.544 0 00-1.087-.002v1.002a.29.29 0 11-.581 0l.001-1.038h-.003a.543.543 0 00-1.086.038.29.29 0 11-.58 0 1.125 1.125 0 011.957-.753 1.126 1.126 0 011.96.753z" fill="currentColor"></path></svg>
        }
        return <></>
    }
}

export default NoticeIcon;