import React, { Component } from 'react';
import { formatPercents, formatPrice } from '../lib/functions';

class ToolbarGainer extends Component {
    onElClick = () => {
        let coinSymbol = this.props.coinSymbol || '';
        if (coinSymbol && typeof this.props.onClick === 'function') {
            this.props.onClick.call(null, coinSymbol);
        }
    }

    render() {
        let coinPrice = this.props.coinPrice || '';
        let coinPriceOutput = coinPrice !== '' && this.props.includeCoinPrice ? <span className="toolbar--gainer--current-price">{formatPrice(coinPrice)}</span> : null;
        let wrapClass = 'toolbar--gainers--gainer trend-color';
        if (coinPriceOutput !== null) {
            wrapClass += ' el--includes-price';
        }
        return <div className={wrapClass} data-trend={this.props.trend ? this.props.trend : (this.props.value < 0 ? 'd' : 'u')} onClick={this.onElClick}><span className="toolbar--gainer--name">{this.props.name} {this.props.trend ? "(" + this.props.trend + ")" : ""}</span> {coinPriceOutput} <span className="toolbar--gainer--value">{formatPercents(this.props.value)}</span></div>
    }
}

export default ToolbarGainer;